import React from "react";
import { connect } from "react-redux";
import { toggleSidebar, logout } from "../redux/actions/sidebarActions";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import "./logo.scss";
import * as myConstClass from "../constant.js";
import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Form,
  Button,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelopeOpen,
  faCog,
  faArrowAltCircleRight,
  faExclamationTriangle,
  faShoppingCart,
  faHandsHelping,
} from "@fortawesome/free-solid-svg-icons";

const NavbarDropdown = ({
  children,
  count,
  header,
  footer,
  icon,
  active,
  indicator,
}) => (
  <UncontrolledDropdown nav inNavbar className="ml-lg-1" active={active}>
    <DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
      <FontAwesomeIcon icon={icon} className="align-middle" />
      {indicator ? <span className="indicator" /> : ""}
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted">{footer}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const NavbarToggle = connect((store) => ({
  app: store.app,
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
});

const NavbarSearch = connect((store) => ({
  sidebar: store.sidebar,
}))(() => {
  return (
    <Form inline>
      {/* <Input
        type="text"
        placeholder="Search projects..."
        aria-label="Search"
        className={classNames(
          "form-control-lite",
          !sidebar.isOnRight || "text-right"
        )}

      /> */}
    </Form>
  );
});

class Navbarnew extends React.Component {
  constructor(props) {
    super(props);
    this.edit = this.edit.bind(this);
    this.googleTranslateElementInit =
      this.googleTranslateElementInit.bind(this);
    this.state = {};
    this.state = {
      issueLength: 0,
      empData: [],
      msgcount: "",
      ordercount: "",
      complaintcount: "",
      leavecount: "",
      documentcount: "",
      pro_package: "",
      unseencount: "",
    };
  }

  componentDidMount() {
    //window.location.reload(false);

    window.googleTranslateElementInit = this.googleTranslateElementInit;
    const script = document.createElement("script");
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);
    var admin_id = localStorage.getItem("uname");
    let login_id = localStorage.getItem("login_id");
    var login_type = localStorage.getItem("login_type");
    //alert(login_type);
    //alert(localStorage.getItem("lang"));

    fetch(myConstClass.BASE_URL + "attendance/getEmployeeIssues/0/1/"+admin_id)
      .then((response) => response.json())
      .then((response) => {
        let i = 1;

        this.setState({ issueLength: response.length });
      })
      .catch((err) => console.log(err));
  }

  tick() {
    // alert('hii');
    var admin_id = localStorage.getItem("uname");
    var login_type = localStorage.getItem("login_type");
    var login_id = localStorage.getItem("login_id");

    fetch(myConstClass.BASE_URL + "attendance/getEmployeeIssues/0/1/"+admin_id)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ issueLength: response.length });
      })
      .catch((err) => console.log(err));
  }

  googleTranslateElementInit() {
    /* eslint-disable no-new */
    const google = window.google;
    // new google.translate.TranslateElement({pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.SIMPLE, autoDisplay: false, multilanguagePage: true}, 'google_translate_element');
    new google.translate.TranslateElement(
      { pageLanguage: "en" },
      "google_translate_elementnav"
    );
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "pt",
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      "google_translate_elementnav"
    );
  }

  edit(e) {
    //alert(e);
    var add_msg = localStorage.getItem("view_msg");
    var login_type = localStorage.getItem("login_type");

    // if (e == "issue") {
    //   var empleave_add = localStorage.getItem("issue");

      // if (
      //   (empleave_add == "0" && login_type !== "hr_admin") ||
      //   (empleave_add == null && login_type !== "hr_admin") ||
      //   login_type == "emp" ||
      //   login_type == "security"
      // ) {
      //   // alert('hii')
      //   toastr.error("Error", add_msg);
      //   return;
      // }

      // fetch(myConstClass.BASE_URL + "user/getunreadunseenCountcomplaint")
      //   .then((response) => response.json())
      //   .then(() => {})
      //   .catch((err) => console.log(err));
      window.location.href = "/" + "employeesissues/listing";
    //  }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    //alert(localStorage.getItem("lang"));
    const { sidebar } = this.props;
    var admin_id = localStorage.getItem("uname");
    let login_id = localStorage.getItem("login_id");
    var login_type = localStorage.getItem("login_type");
    var is_google_transaltor = localStorage.getItem("is_google_transaltor");
    var is_subadmin = localStorage.getItem("is_subadmin");
    // alert(is_subadmin);
    // alert(login_type);
    // alert(is_payroll_privilage);

    let admin_path, tenpath;
    if (login_type == "hr_admin") {
      tenpath = "myprofile/Add";
    } else {
      tenpath = "employee/add";
    }

    admin_path = "/";

    return (
      <Navbar expand className="navbar-theme">
        {!sidebar.isOnRight ? (
          <React.Fragment>
            <NavbarToggle />

            <NavbarSearch />

            <Collapse navbar>
              <Nav
                className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"}
                navbar
              >
                <div id="google_translate_elementnav"></div>

                {this.state.issueLength != 0 && admin_id!=0 &&
                this.state.issueLength != null ? (
                  <UncontrolledDropdown nav inNavbar className="ml-lg-1">
                    <DropdownToggle
                      nav
                      className="nav-icon dropdown-toggle position-relative"
                    >
                      <FontAwesomeIcon
                        icon={faHandsHelping}
                        className="align-middle"
                        onClick={() => this.edit("issue")}
                      />
                      <span class="float-right badge badge-error">
                        {this.state.issueLength}
                      </span>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                ) : this.state.issueLength == 0 && admin_id!=0 ||
                  this.state.issueLength == null && admin_id!=0 ? (
                  <UncontrolledDropdown nav inNavbar className="ml-lg-1">
                    <DropdownToggle
                      nav
                      className="nav-icon dropdown-toggle position-relative"
                    >
                      <FontAwesomeIcon
                        icon={faHandsHelping}
                        className="align-middle"
                        onClick={() => this.edit("issue")}
                      />
                    </DropdownToggle>
                  </UncontrolledDropdown>
                ) : (
                  ""
                )}

                {/* for google translator */}

                <UncontrolledDropdown nav inNavbar className="ml-lg-1">
                  {/* <div  id="google_translate_element"></div> */}
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon icon={faCog} className="align-middle" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    {/* {login_type == "hr_admin" ? (
                      // if(login_type == 'hr_admin'){
                      // <Link
                      //   to={{
                      //     pathname:
                      //       "/" +
                          
                      //       "myprofile/Add",
                      //     state: {
                      //       _id: { admin_id },
                      //     },
                      //   }}
                      // >
                      //   {" "}
                        <DropdownItem onClick={event =>  window.location.href='/myprofile/Add/:id'}>
                          <FontAwesomeIcon
                            icon={faArrowAltCircleRight}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          My Profile
                        </DropdownItem>
                      // </Link>
                    ) : (
                      <Link
                        to={{
                          pathname:
                            "/" +
                         
                            "employee/profile",
                          state: {
                            _id: { login_id },
                          },
                        }}
                      >
                        {" "}
                        <DropdownItem>
                          <FontAwesomeIcon
                            icon={faArrowAltCircleRight}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          My Profile
                        </DropdownItem>
                      </Link>
                    )} */}
                    <DropdownItem
                      onClick={(event) =>
                        (window.location.href = "/myprofile/Add/:id")
                      }
                    >
                      <FontAwesomeIcon
                        icon={faArrowAltCircleRight}
                        fixedWidth
                        className="mr-2 align-middle"
                      />
                      My Profile
                    </DropdownItem>
                    <Link to={admin_path}>
                      <DropdownItem
                        onClick={() => {
                          logout();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faArrowAltCircleRight}
                          fixedWidth
                          className="mr-2 align-middle"
                        />
                        Sign out
                      </DropdownItem>
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Collapse navbar>
              <Nav
                className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"}
                navbar
              >
                {/* for google translator */}

                <UncontrolledDropdown nav inNavbar className="ml-lg-1">
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon icon={faCog} className="align-middle" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    {login_type == "hr_admin" ? (
                      // if(login_type == 'hr_admin'){
                      <Link
                        to={{
                          pathname: "/" + "myprofile/Add",
                          state: {
                            _id: { admin_id },
                          },
                        }}
                      >
                        {" "}
                        <DropdownItem>
                          <FontAwesomeIcon
                            icon={faArrowAltCircleRight}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          My Profile
                        </DropdownItem>
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: "/" + "employee/profile",
                          state: {
                            _id: { login_id },
                          },
                        }}
                      >
                        {" "}
                        <DropdownItem>
                          <FontAwesomeIcon
                            icon={faArrowAltCircleRight}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          My Profile
                        </DropdownItem>
                      </Link>
                    )}

                    <Link to={admin_path}>
                      <DropdownItem
                        onClick={() => {
                          logout();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faArrowAltCircleRight}
                          fixedWidth
                          className="mr-2 align-middle"
                        />
                        Sign out
                      </DropdownItem>
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>

            <NavbarSearch />
            <NavbarToggle />
            {/* <div  id="google_translate_element"></div> */}
          </React.Fragment>
        )}
      </Navbar>
    );
  }
}

// const NavbarComponent = ({ sidebar }) => {
//   return (
//     <Navbar expand className="navbar-theme">
//       {!sidebar.isOnRight ? (
//         <React.Fragment>
//           <NavbarToggle />
//           <NavbarSearch />
//           <NavbarDropdowns />
//         </React.Fragment>
//       ) : (
//         <React.Fragment>
//           <NavbarDropdowns />
//           <NavbarSearch />
//           <NavbarToggle />
//         </React.Fragment>
//       )}
//     </Navbar>
//   );
// };

export default connect((store) => ({
  sidebar: store.sidebar,
}))(Navbarnew);
