// export const BASE_URL = "https://alpha.workzoneplus.com/backend/";
 //export const BASE_URL =  'http://localhost/saastest/backend/'; 
// export const BASE_URL = "https://workzonepro.workzoneplus.com/backend/";



//export const BASE_URL = "http://localhost/beta/backend/";
export const BASE_URL = "https://beta.workzoneplus.com/backend/";
export const ADDMSG='Record Added Successfully';
export const UPDATEMSG='Record Updated Successfully';
export const DELETEMSG='Record Deleted Successfully';
export const ERRORMSG='Can not Add the Record';
