import React from "react";
import { Container, Row, Col } from "reactstrap";

import Calendar from "./Calendar";
import LineChart from "./LineChart";

import Statistics from "./Statistics";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import HeaderSubtitle from "../../../components/HeaderSubtitle";

const Default = () => (
  
  <Container fluid>
    
    <Header> 
   
      <HeaderTitle>Welcome back,{localStorage.getItem('utype')} !</HeaderTitle>
      <HeaderSubtitle>
      </HeaderSubtitle>
    </Header>

    <Row>
   
      <Col xl="12" >
        <Statistics />
      </Col>
    </Row>
    <Row>
    <Col xl="6" className="col-xxl-6">
        <LineChart />
      </Col>
     
      <Col md="6" className="col-xxl-6" >
        {/* <PieChart /> */}
        <Calendar />
      </Col>
    </Row>
    <Row>
      <Col lg="8" className="col-xxl-6">
        {/* <Table /> */}
      </Col>
      <Col lg="4" className="col-xxl-6">
        {/* <BarChart /> */}
      </Col>
    </Row>
  </Container>
);

export default Default;
