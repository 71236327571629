import React,{ PureComponent } from "react";
import * as myConstClass from '../../../constant.js';
import { Card, CardBody, CardTitle, Col, Row ,CardLink} from "reactstrap";

// import { Truck, Users, DollarSign, ShoppingCart } from "react-feather";
import { Truck, Users,Clock,DollarSign,Calendar,List ,ShoppingCart, Terminal,Menu } from "react-feather";
import { withRouter } from 'react-router-dom';
import { MoreVertical, RefreshCw } from "react-feather";

class Statistics extends PureComponent {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
   // //this.googleTranslateElementInit = this.googleTranslateElementInit.bind(this);
      
    this.state = {
      user_id:0,
      checkin: [],
      leaves: [],
      org: [],
      emp: [],
      formData:'',
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    }
  }

  routeChange(){
    this.props.history.push('/');
  }
  componentDidMount(){
    
    // window.googleTranslateElementInit = this.googleTranslateElementInit
    // const google = window.google;
    // const script = document.createElement("script");
    // script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    // script.async = true;
    // document.body.appendChild(script);

    var admin_id = localStorage.getItem('uname'); 
    if(admin_id == null){
      this.routeChange();
    }

    fetch(myConstClass.BASE_URL+'attendance/getCheckInInfo/'+admin_id)
    .then(response => response.json())
    .then(response =>{this.setState({checkin: response})}).catch(err => console.log(err))

   fetch(myConstClass.BASE_URL+'attendance/getThisMonthLeaves/'+admin_id)
  .then(response => response.json())
  .then(response =>{this.setState({leaves: response})}).catch(err => console.log(err))

  fetch(myConstClass.BASE_URL+'organization/getOrgCount/'+admin_id)
  .then(response => response.json())
  .then(response =>{this.setState({org: response})}).catch(err => console.log(err))

  fetch(myConstClass.BASE_URL+'user/getActiveEmployees/'+admin_id)
  .then(response => response.json())
  .then(response =>{this.setState({emp: response})}).catch(err => console.log(err))
  }
  // googleTranslateElementInit () {
  //   /* eslint-disable no-new */
  //   const google = window.google;
  //     new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element_final');
  //   new window.google.translate.TranslateElement({pageLanguage: 'pt', layout: window.google.translate.
  //   TranslateElement.FloatPosition.TOP_LEFT}, 'google_translate_element_final')
  // }
  show_att(e) {
    if(e=='1')
    {
    this.props.history.push(
      "/" + "attendance/listing"
    );
  }
  if(e=='2')
    {
    this.props.history.push(
      "/" + "leaves/listing"
    );
  }
  if(e=='3')
    {
    this.props.history.push(
      "/" + "settingsorganization/listing"
    );
  }
  if(e=='4')
    {
    this.props.history.push(
      "/" + "employee/listing"
    );
  }
  }
  render() {
    const { checkin,leaves,org,emp} = this.state;
    var admin_id = localStorage.getItem('uname'); 
    return (
      <div className="w-100">
    <Row>
      <Col sm="3">
        <Card style={{'height': '142px'}}>
        <CardLink onClick={() => this.show_att(1)}>
          <CardBody>
            <Row>
              <Col className="mt-0">
                <CardTitle tag="h5">TODAY'S ATTENDANCE</CardTitle>
              </Col>
              <Col className="col-auto">
                <div className="avatar">
                  <div className="avatar-title rounded-circle bg-primary-dark">
                    {/* <Truck className="feather align-middle" /> */}
                    <Calendar className="feather align-middle" />
                  </div>
                </div>
              </Col>
            </Row>
            <h1 className="display-5 mt-1 mb-3">{checkin.total_count}</h1>
          </CardBody>
          </CardLink>
        </Card>
        </Col>
      <Col sm="3">
        <Card style={{'height': '142px'}}>
        <CardLink onClick={() => this.show_att(2)}>
          <CardBody>
            <Row>
              <Col className="mt-0">
                <CardTitle tag="h5">THIS MONTH LEAVE</CardTitle>
              </Col>
              <Col className="col-auto">
                <div className="avatar">
                  <div className="avatar-title rounded-circle bg-primary-dark">
                    {/* <Users className="feather align-middle" /> */}
                    <Menu className="feather align-middle" />
                  </div>
                </div>
              </Col>
            </Row>
            <h1 className="display-5 mt-1 mb-3">{leaves.total_count}</h1>
          </CardBody>
          </CardLink>
        </Card>
      </Col>
      <Col sm="3">
        <Card style={{'height': '142px'}}>
        <CardLink onClick={() => this.show_att(3)}>
          <CardBody>
            <Row>
              <Col className="mt-0">
                <CardTitle tag="h5">TOTAL ORGANIZATIONS</CardTitle>
              </Col>
              <Col className="col-auto">
                <div className="avatar">
                  <div className="avatar-title rounded-circle bg-primary-dark">
                    {/* <DollarSign className="feather align-middle" /> */}
                    <List className="feather align-middle" />
                  </div>
                </div>
              </Col>
            </Row>
            <h1 className="display-5 mt-1 mb-3">{org.total_count}</h1>
          </CardBody>
          </CardLink>
        </Card>
        </Col>
      <Col sm="3">
        <Card style={{'height': '142px'}}>
        <CardLink onClick={() => this.show_att(4)}>
          <CardBody>
            <Row>
              <Col className="mt-0">
                <CardTitle tag="h5">ACTIVE EMPLOYEES</CardTitle>
              </Col>
              <Col className="col-auto">
                <div className="avatar">
                  <div className="avatar-title rounded-circle bg-primary-dark">
                    {/* <ShoppingCart className="feather align-middle" /> */}
                    <Users className="feather align-middle" />
                  </div>
                </div>
              </Col>
            </Row>
            <h1 className="display-5 mt-1 mb-3">{emp.total_count}</h1>
          </CardBody>
          </CardLink>
        </Card>
      </Col>
    </Row>
  </div>
  
    );
  }
}

// const Statistics = () => (


    
 
  
  
//   <div className="w-100">
//     <Row>
//       <Col sm="6">
//         <Card>
//           <CardBody>
//             <Row>
//               <Col className="mt-0">
//                 <CardTitle tag="h5">TODAY'S ATTENDANCE</CardTitle>
//               </Col>

//               <Col className="col-auto">
//                 <div className="avatar">
//                   <div className="avatar-title rounded-circle bg-primary-dark">
//                     <Truck className="feather align-middle" />
//                   </div>
//                 </div>
//               </Col>
//             </Row>
//             <h1 className="display-5 mt-1 mb-3">{checkin.total_count}</h1>
//             {/* <div className="mb-0">
//               <span className="text-danger">
//                 <i className="mdi mdi-arrow-bottom-right"></i> -2.65%
//               </span>{" "}
//               Less sales than usual
//             </div> */}
//           </CardBody>
//         </Card>
//         <Card>
//           <CardBody>
//             <Row>
//               <Col className="mt-0">
//                 <CardTitle tag="h5">Visitors Today</CardTitle>
//               </Col>

//               <Col className="col-auto">
//                 <div className="avatar">
//                   <div className="avatar-title rounded-circle bg-primary-dark">
//                     <Users className="feather align-middle" />
//                   </div>
//                 </div>
//               </Col>
//             </Row>
//             <h1 className="display-5 mt-1 mb-3">17.212</h1>
//             <div className="mb-0">
//               <span className="text-success">
//                 <i className="mdi mdi-arrow-bottom-right"></i> 5.50%
//               </span>{" "}
//               More visitors than usual
//             </div>
//           </CardBody>
//         </Card>
//       </Col>
//       <Col sm="6">
//         <Card>
//           <CardBody>
//             <Row>
//               <Col className="mt-0">
//                 <CardTitle tag="h5">Total Earnings</CardTitle>
//               </Col>

//               <Col className="col-auto">
//                 <div className="avatar">
//                   <div className="avatar-title rounded-circle bg-primary-dark">
//                     <DollarSign className="feather align-middle" />
//                   </div>
//                 </div>
//               </Col>
//             </Row>
//             <h1 className="display-5 mt-1 mb-3">$24.300</h1>
//             <div className="mb-0">
//               <span className="text-success">
//                 <i className="mdi mdi-arrow-bottom-right"></i> 8.35%
//               </span>{" "}
//               More earnings than usual
//             </div>
//           </CardBody>
//         </Card>
//         <Card>
//           <CardBody>
//             <Row>
//               <Col className="mt-0">
//                 <CardTitle tag="h5">Pending Orders</CardTitle>
//               </Col>

//               <Col className="col-auto">
//                 <div className="avatar">
//                   <div className="avatar-title rounded-circle bg-primary-dark">
//                     <ShoppingCart className="feather align-middle" />
//                   </div>
//                 </div>
//               </Col>
//             </Row>
//             <h1 className="display-5 mt-1 mb-3">43</h1>
//             <div className="mb-0">
//               <span className="text-danger">
//                 <i className="mdi mdi-arrow-bottom-right"></i> -4.25%
//               </span>{" "}
//               Less orders than usual
//             </div>
//           </CardBody>
//         </Card>
//       </Col>
//     </Row>
//   </div>
// );

// export default Statistics;
export default withRouter(Statistics);
