import React,{ PureComponent } from "react";
import * as myConstClass from '../../../constant.js';
import './header.scss';
import {
  Card, Container,Table,
  CardBody,
  CardTitle} from "reactstrap";

// const Ava  = `https://alpha.workzoneplus.com/img/profile.png`;
const Ava  = `https://workzonepro.workzoneplus.com/img/profile.png`;
class LineChart extends PureComponent {
  constructor(props) {
    super(props);
    // this.addState = this.addState.bind(this);
    this.state = {
      user_id:0,
      count: 1,
      total_no:0,
      selectedOption:false,
      checkin: [],
      absent: [],
      posts: [],
      emp: [],
      arr:[],
      formData:'',
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    }
    this.headers = [
      { displayName: "Employee", id: "employee" },
      { displayName: 'Attendance Date',id: "attendance_date"},  
      { displayName: "Department", id: "department" },
      { displayName: "Organization", id: "organization" },

    ];
  }



  // addState(){

  //   let arr1 = this.state.arr;
  //   arr1.concat(2);
  //   this.setState({arr: arr1});
  //   // alert(this.state.arr);
  //   }


  componentDidMount() {
    // window.location.reload(false
    // var selectedOption = localStorage.getItem( 'SelectedOption' ) || 1; 
    // localStorage.setItem( 'SelectedOption', selectedOption +1 );
    // this.setState(() => ({
    //   selectedOption: selectedOption
    // })) ;
// alert(selectedOption+'   '+ selectedOption);
   
    // var selectedOption = localStorage.getItem( 'SelectedOption' ) || 1; 
    // alert(selectedOption);
    // var result_len = selectedOption.length;
    // var upcoming_cnt =selectedOption+1; 
    // var upc_len = upcoming_cnt.length;
    // localStorage.setItem( 'SelectedOption', selectedOption +1 );
    // this.setState( { selectedOption: selectedOption  } );alert("B"+'  '+ selectedOption);
    // if(selectedOption <= selectedOption){ alert("f");

    // //  window.location.reload();
    // //  window.location.reload(false);
     
    // }
    // this.addState();
    // this.setState({
    //   arr:  this.state.arr.push(2),
    //   count: this.state.count
    // })
    // this.state.arr.concat(1); 
    // this.state.arr.push(1);
    // this.setState({arr: this.state.arr});





    var login_type= localStorage.getItem('login_type');
  var admin_id = localStorage.getItem('uname'); 















    if(login_type=='sub_admin')
    {
     var org_id=localStorage.getItem('organization_sub_admin');
      var dept_id=localStorage.getItem('department_sub_admin'); 
    }
    else{
    
      var org_id=0;
      var dept_id=0;
    }
    






    fetch(myConstClass.BASE_URL+'attendance/getAbsentEmp/'+admin_id+'/'+org_id+'/'+dept_id)
    .then(response => response.json())
    .then(response =>{
      response.forEach(element => {
        this.setState({total_no:element.no})
      });
    ///  alert(response.no);
      this.setState({posts:response})
      //this.setState({total_no:response.no})
  
  
  
  }).catch(err => console.log(err))   
        
    fetch(myConstClass.BASE_URL+'attendance/getAllAbsentEmp/'+admin_id+'/'+org_id+'/'+dept_id)
    .then(response => response.json())
    .then(response =>{this.setState({absent: response})}).catch(err => console.log(err))  
 


  }

  
  render() {
 
    // if( window.localStorage )
    // {
    //   if( !localStorage.getItem('firstLoad') )
    //   {
    //     localStorage['firstLoad'] = true;
    //     window.location.reload();
    //   } else {
    //     // localStorage.removeItem('firstLoad');
    //   }
    // }
    // window.location.reload();
    // window.localStorage.setItem('refresh', "1");
    
    const {posts,absent,posts1} = this.state;
    const csvData = this.state.absent.rows;
    const headers = this.headers;
    let fileName = 'Absent_Employees_'+new Date().getTime()+'.csv';
    let tableData;
    let tableHeader;
    if(posts == ''){
      tableData = <h4 className="heading">Data Not Available....!</h4>;
      tableHeader=<h4 className="heading">  Today's Absent Employee </h4>;
    } else {
      tableHeader=<h4 className="heading">  Today's Absent Employee {this.state.total_no} </h4>;
     tableData = posts.map((posts) =>


     <tr>
     <td><img className="topbar__avatar-img" src={Ava} alt="avatar" /></td>
     <td>{posts.employee}</td>
    
     <td> {posts.department+' / '+posts.organization}</td>
     {/* <td>{posts1.expiry_date}</td> */}
     
 </tr>








          // <div class='col-md-12'>
          //      <div class='col-md-1'>
          //         <img className="topbar__avatar-img" src={Ava} alt="avatar" /> </div>
          //           <div class='col-md-11' 
                    
          //           // style={{width:'306px', top: '-34px', left: '54px'}}
                    
          //           >
          //           {posts.employee} <br/>   {posts.department+' / '+posts.organization}   
          //       </div>
          //   </div>

    );
    }

    

    return (
     
        <Container>
         

         <Card>
          <CardTitle tag="h3" className="mb-0">
           <h4 className="heading"> {tableHeader} </h4>
          </CardTitle><br/>
          <CardBody className="py-3">
          <div class="ScrollStyle" id="vertical_scroll">
          <Table striped className="my-0">
            {/* <thead>
                <tr>
                  <th>Image</th>
                  <th className="d-none d-xl-table-cell">Name</th>
                  <th className="d-none d-xl-table-cell">From Date</th>
                  <th className="d-none d-xl-table-cell">To Date</th>
                  <th className="d-none d-md-table-cell">Leave Type</th>
                </tr>
              </thead> */}
              <tbody>
                    {tableData}
                </tbody>
               </Table>
               </div>
             </CardBody>
           </Card>


























       














         </Container>
    );
  }

}



// const LineChart = ({ theme }) => {
//   const data = {
//     labels: ["Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
//     datasets: [
//       {
//         label: "Orders",
//         fill: true,
//         backgroundColor: theme.primary,
//         borderColor: theme.primary,
//         borderWidth: 2,
//         data: [3, 2, 3, 5, 6, 5, 4, 6, 9, 10, 8, 9]
//       },
//       {
//         label: "Sales ($)",
//         fill: true,
//         backgroundColor: "rgba(0, 0, 0, 0.05)",
//         borderColor: "rgba(0, 0, 0, 0.05)",
//         borderWidth: 2,
//         data: [5, 4, 10, 15, 16, 12, 10, 13, 20, 22, 18, 20]
//       }
//     ]
//   };

//   const options = {
//     maintainAspectRatio: false,
//     legend: {
//       display: false
//     },
//     tooltips: {
//       intersect: false
//     },
//     hover: {
//       intersect: true
//     },
//     plugins: {
//       filler: {
//         propagate: false
//       }
//     },
//     elements: {
//       point:{
//         radius: 0
//       }
//     },
//     scales: {
//       xAxes: [{
//         reverse: true,
//         gridLines: {
//           color: "rgba(0,0,0,0.0)"
//         }
//       }],
//       yAxes: [{
//         ticks: {
//           stepSize: 5
//         },
//         display: true,
//         gridLines: {
//           color: "rgba(0,0,0,0)",
//           fontColor: "#fff"
//         }
//       }]
//     }
//   };

//   return (
//     <Card className="flex-fill w-100">
//       <CardHeader>
//         <div className="card-actions float-right">
//           <span className="cursor-pointer mr-1">
//             <RefreshCw />
//           </span>{" "}
//           <UncontrolledDropdown className="d-inline-block">
//             <DropdownToggle tag="a">
//               <MoreVertical />
//             </DropdownToggle>
//             <DropdownMenu right>
//               <DropdownItem>Action</DropdownItem>
//               <DropdownItem>Another Action</DropdownItem>
//               <DropdownItem>Something else here</DropdownItem>
//             </DropdownMenu>
//           </UncontrolledDropdown>
//         </div>
//         <CardTitle tag="h5" className="mb-0">
//           Recent Movement
//         </CardTitle>
//       </CardHeader>
//       <CardBody className="py-3">
//         <div className="chart chart-sm">
//           <Line data={data} options={options} />
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

// export default connect(store => ({
//   theme: store.theme.currentTheme
// }))(LineChart);
 export default LineChart;