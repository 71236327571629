import React , { PureComponent } from "react";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import * as myConstClass from '../../constant.js';
import $ from 'jquery'; 
import { toastr } from "react-redux-toastr";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap";

import avatar from "../../assets/img/avatars/avatar.jpg";
import { EXITED } from "react-transition-group/Transition";
const image  = `https://beta.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;
const image2 = `https://beta.workzoneplus.com/backend/img/clock.png`;
const image3 = `https://beta.workzoneplus.com/img/logo2.png`;


// const {logout} = props.location.state;
class LogInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      toDashboard: false,
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
      submitted: false,
    };
    this.login = this.login.bind(this);
  }

  onFormSubmit = () => {
  this.setState({ submitted: true }, () => {  
    setTimeout(() => this.setState({ submitted: false }), 5000);

    fetch(myConstClass.BASE_URL+`user/forgetpassword/`, {
      method: 'POST',
      body: JSON.stringify($('#pform').serializeArray()),
      }).then(response =>  response.json()).then((response) => {
       
        if(response == 2){ 
          toastr.error(
             'Error',
             "Please enter email..!",
             this.options
           )
       } else
       if(response == 3){ 
        toastr.error(
          'Error',
          "Email Does not exists..!",
          this.options
        )
     } else if(response == 4){
      toastr.success(
        'Success',
        "Email Sent Successfully..!",
        this.options
      )
     } else {
       toastr.error(
         'Error',
         "Can not send email ..!",
         this.options
       )
     }
      })
      .catch();   


  });
}

  login(e) {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };
    e.preventDefault();
    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
   
    console.log('-->', formData);
    fetch(myConstClass.BASE_URL+`user/forgetpassword/`, {
    method: 'POST',
    body: JSON.stringify($('#pform').serializeArray()),
    }).then(response =>  response.json()).then((response) => {
     
      if(response == 2){ 
        toastr.error(
           'Error',
           "Please enter email..!",
           this.options
         )
     } else
     if(response == 3){ 
      toastr.success(
        'Success',
        "Password Successfully Changed..!",
        this.options
      )
   } else if(response == 4){
    toastr.success(
      'Success',
      "Password Successfully Changed..!",
      this.options
    )
   } else {
     toastr.error(
       'Error',
       "Login Unsuccessful ..!",
       this.options
     )
   }
    })
    .catch();   
  }

  render() {
    const { formData,submitted} = this.state;
    if (this.state.toDashboard === true) {
      window.location.href = '/dashboard'; 
      return <Redirect to='/dashboard' />
    }
return(
  <React.Fragment>
  <div className="text-center mt-4">
      {/* <h1 className="h2">Reset password</h1> */}
      <p className="lead">Enter your email to reset your password.</p>
    </div>
    <Card>
      <CardBody>
        <div className="m-sm-4">
          <div className="text-center">
          {/* <img src={image2} style={{'width':'40px','height':'40px'}}></img> */}
          <img src={image2} style={{'width':'40px','height':'40px;' }} ></img>
          <img src={image} style={{'width':'250px','height':'38px;'}}></img>
           
          </div><br/><br/>
          <Form className="form" id="pform"  onSubmit={this.onFormSubmit}  >
            <FormGroup>
              <Label>Email</Label>
              <Input
                bsSize="lg"
                type="email"
                name="email"
                placeholder="Enter your email"
              />
            </FormGroup>
            {/* <Link to="auth/reset"></Link> */}
            {/* <small>
                <Link to="/auth/reset">Forgot password?</Link>
              </small> */}
            <div className="text-center mt-3">    
            {/* <Link to="/dashboard"> 
               <Button color="primary" size="lg"  onSubmit={this.onFormSubmit}>               
               Sign in
             </Button>  
              </Link> */}
             {/* <a href="http://localhost:3000/dashboard" > 
              <Button color="primary" size="lg"  onSubmit={this.onFormSubmit}>               
               Sign in
             </Button>        
           </a> */}
         </div> 
         {/* <a href="<?=site_url('user_verification? email=' . $email );?> ">Click To Verifiy Email </a> */}

            <div className="text-center mt-3">
            {/* <a href="<?=site_url('user_verification?email=' . $email );?> ">Click To Verifiy Email </a> */}

              {/* <Link to="auth/reset"> */}
                <Button color="primary" size="lg" onClick={this.onFormSubmit}>
               submit
                </Button>
                {/* <Button disabled={submitted}>{
                              (submitted && 'Your form is submitted!')
                              || (!submitted && 'Submit')
                          }</Button> */}
              {/* </Link> */}
            </div>
           
            {/* <center><img src={image3} style={{'width':'40%'}}></img><br/>    */}
       {/* <p><a href="https://www.epenh.com" target="_blank">Developed by Epenh Co. Ltd © 2019</a></p></center> */}
    
          </Form>
        </div>
      </CardBody>
    </Card>
    {/* <center><img src={image3} style={{'width':'35%'}}></img><br/>   
       <p><a href="https://www.epenh.com" target="_blank">Developed by Epenh Co. Ltd © 2019</a></p></center> */}
  </React.Fragment>
)
  }
}

export default LogInForm;
